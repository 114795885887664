import React from 'react';
import Layout from '../components/layout';
import styles from './errortemplate.module.scss';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <>
      <Helmet>
        <title>Outage - Trustaff</title>
      </Helmet>
      <Layout>
        <div className={`section ${styles.errorSection}`}>
          <div className={`${styles.errorBox}`}>
            <h1 className={`${styles.mainHeading}`}>We’ll be right back</h1>
            <p className={`${styles.body}`}>
              We are currently upgrading our systems. If you are a Trustaff
              traveler currently on assignment, please reach out to your
              recruiter for immediate assistance. For general inquiries, please
              call
              <br />
              <span className={`${styles.metaBody}`}>(877) 880-0346</span>
            </p>
            <div className={`${styles.errorHR}`} />
            <p className={`${styles.metaBody}`}>
              In the meantime, check out the great benefits of working with
              Trustaff.
            </p>
            <div className={`${styles.moreDeets}`}>
              <Link
                className={`${styles.errorCta}`}
                role="link"
                to="/nurses/salary-benefits/"
              >
                <div className={`${styles.errorCtaInnerWrap}`}>
                  <div className={`${styles.errorCtaInner}`}>
                    <span className={`${styles.span}`}>
                      Nursing&nbsp;benefits
                    </span>
                  </div>
                </div>
              </Link>

              <Link
                className={`${styles.errorCta}`}
                role="link"
                to="/allied-health/benefits/"
              >
                <div className={`${styles.errorCtaInnerWrap}`}>
                  <div className={`${styles.errorCtaInner}`}>
                    <span className={`${styles.span}`}>
                      Allied&nbsp;benefits
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
